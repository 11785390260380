import React, { useEffect, useState, useRef, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import ScriptTag from 'react-script-tag';
import useScript from './hooks/useScript';




import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/slick.css';
import './assets/css/vendor/slick-theme.css';
import './assets/css/vendor/nice-select.css';
import './assets/css/plugins/feature.css';
//import './assets/css/plugins/jquery-ui.min.css';
import './assets/css/style-gerty-punks-1-0.css';

import GertyOne from './assets/images/gerty-punks-1-0/gerty-punks-1-0-nft01-400x400.jpg';
import GertyTwo from './assets/images/gerty-punks-1-0/gerty-punks-1-0-nft02-400x400.jpg';
import GertyThree from './assets/images/gerty-punks-1-0/gerty-punks-1-0-nft03-400x400.jpg';
import GertyFour from './assets/images/gerty-punks-1-0/gerty-punks-1-0-nft04-400x400.jpg';
import GertyTopLight from './assets/images/gerty/logo-gerty-nft.png';
import GertyTopDark from './assets/images/gerty/logo-gerty-nft-dark.png';
import NFTCalendarImg from './assets/images/gerty/nft-calendar-transparent.png';
import OGGerty1 from './assets/images/gerty-og/gerty-og-samp02-298x199.png';

import OGGerty2 from './assets/images/gerty-og/gerty-og-samp01-298x199.png';
import OGGerty3 from './assets/images/gerty-og/gerty-og-samp03-298x199.png';
import OGGerty4 from './assets/images/gerty-og/gerty-og-samp04-298x199.png';

import * as $ from 'jquery';
/* import './assets/js/vendor/jquery'
/* import "jquery-nice-select/css/style.css"; */
/*import './assets/js/vendor/jquery.nice-select.min'; */






const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;



function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    console.log("account", blockchain.account);
    console.log("mintamount", mintAmount);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 200) {
      newMintAmount = 200;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };
  
  

  /* const ScriptsComponent = props => {
    useScript('https://use.typekit.net/foobar.js');
    useScript("./assets/js/vendor/jquery.js");
    useScript("./assets/js/vendor/jquery.nice-select.min.js");
    useScript('./assets/js/vendor/feather.min.js');
    useScript("./assets/js/vendor/slick.min.js");
    useScript('./assets/js/vendor/bootstrap.min.js');
    useScript('./assets/js/vendor/sal.min.js');
    useScript('./assets/js/vendor/wow.js');
    useScript('./assets/js/vendor/particles.js');
    useScript('./assets/js/vendor/isotop.js');
    useScript('./assets/js/vendor/imageloaded.js');
    useScript('./assets/js/vendor/backtoTop.js');
    useScript('./assets/js/main-gerty.js');
  
    // rest of your component
  } */

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  

   
        return (
          <div>
            <meta charSet="utf-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <title>Gerty Punks 1.0 NFT Collection | Gertrude Token $GERTY | NFT Marketplace</title>
            <meta name="robots" content="noindex, nofollow" />
            <meta name="description" content="Mint your very own NFT from the Gerty Punks 1.0 Collection today! This set is one of the unique Gertrude Token ($GERTY) NFT Collections." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link rel="shortcut icon" type="image/x-icon" href="assets/images/gerty-favicon.png" />
            <link rel="stylesheet" href="./assets/css/vendor/bootstrap.min.css" />
            <link rel="stylesheet" href="./assets/css/vendor/slick.css" />
            <link rel="stylesheet" href="./assets/css/vendor/slick-theme.css" />
            <link rel="stylesheet" href="./assets/css/vendor/nice-select.css" />
            <link rel="stylesheet" href="./assets/css/plugins/feature.css" />
            <link rel="stylesheet" href="./assets/css/plugins/jquery-ui.min.css" />
            <link rel="stylesheet" href="./assets/css/style-gerty-punks-1-0.css" />
            
            <div id="particles-js" />
              
            <header className="rn-header rn-header-four haeder-default black-logo-version header--fixed header--sticky">
           
              <div className="container">
                <div className="header-inner">
                  <div className="header-left">{/* EDIT: HEAD LOGO LINKS */}
                    <div className="logo-thumbnail logo-custom-css">
                      <a className="logo-light" href="https://"><img src={GertyTopLight} alt="Gerty NFT" /></a>
                      <a className="logo-dark" href="https://"><img src={GertyTopDark} alt="Gerty NFT" /></a>
                    </div>
                    <div className="mainmenu-wrapper">
                      <nav id="sideNav" className="mainmenu-nav d-none d-xl-block">{/* EDIT: DESKTOP MENU LINKS */}
                        <ul className="mainmenu">
                          <li><a className="smoth-animation" href="#mint">Mint</a></li>
                          <li><a className="smoth-animation" href="#about">About</a></li>
                          <li><a className="smoth-animation" href="#nfts">All NFTs</a>
                          </li><li><a href="https://opensea.io/collection/gertypunks" target="_blank">OpenSea</a></li>
                          <li><a href="https://gertrudetoken.com/" target="_blank">Gertrude Token</a></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="header-right">
                    <div className="setting-option d-block d-lg-block">
                      <h4 style={{fontWeight: 400, margin: '10px 5px 10px 5px'}}>Gerty Punks 1.0 NFT Collection</h4>
                    </div>
                    <div className="setting-option mobile-menu-bar d-block d-xl-none">
                      <div className="hamberger">
                        <button className="hamberger-button">
                          <i className="feather-menu" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="popup-mobile-menu">
              <div className="inner">
                <div className="header-top">{/* EDIT: MOBILE LOGO LINKS */}
                  <div className="logo logo-custom-css">
                    <a className="logo-light" href="/"><img src={GertyTopLight} alt="Gerty NFT" /></a>
                    <a className="logo-dark" href="/"><img src={GertyTopDark} alt="Gerty NFT" /></a>
                  </div>
                  <div className="close-menu">
                    <button className="close-button">
                      <i className="feather-x" />
                    </button>
                  </div>
                </div>
                <nav>{/* EDIT: MOBILE MENU LINKS */}
                  <ul className="mainmenu">
                    <li className="one-page-navigation-popup"><a className="smoth-animation" href="#mint">Mint</a></li>
                    <li className="one-page-navigation-popup"><a className="smoth-animation" href="#about">About</a></li>
                    <li className="one-page-navigation-popup"><a className="smoth-animation" href="#nfts">All NFTs</a>
                    </li><li><a href="https://opensea.io/collection/gertypunks" target="_blank">OpenSea</a></li>
                    <li><a href="https://gertrudetoken.com/" target="_blank">Gertrude Token</a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="rn-slider-area fullscreen-slide">
              <div className="slider-activation-banner-3 game-banner-slick-wrapper slick-arrow-style-one rn-slick-dot-style slick-gutter-15">
                <div className="bg_image--21 bg_image d-flex align-items-center padding-controler-slide-product justify-content-center slide slide-style-2 fullscreen position-relative" data-black-overlay={5}>
                  <div className="container">
                    <div className="row d-flex align-items-center">
                      <div className="col-lg-7">
                        <div className="inner text-left">
                          <div className="top-bid-price d-flex">{/* EDIT: NFT PRICE */}
                            <span className="latest-bid mr--15">Each NFT mint costs:</span>
                            <div className="last-bid color-primary">TBD {CONFIG.NETWORK.SYMBOL}</div> {/* {CONFIG.DISPLAY_COST}   */}
                          </div>
                          <h1 className="title theme-gradient">Gerty Punks 1.0 NFT Collection</h1>
                        </div>
                      </div>
                      <div className="col-lg-5" id="mint">
                        <div className="place-bet-area into-banner mt_md--30 mt_sm--30">
                          <div className="container nopad" style={{marginTop: '-10px'}}>{/* EDIT: 4 NFT IMAGES 400x400 */}
                            <div className="row align-items-center nopad">
                              <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-center pad-2"><img className="pvthmb" src={GertyOne} alt="Gerty Punks 1.0 NFT" /></div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-center pad-2"><img className="pvthmb" src={GertyTwo} alt="Gerty Punks 1.0 NFT" /></div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-center pad-2"><img className="pvthmb" src={GertyThree} alt="Gerty Punks 1.0 NFT" /></div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-center pad-2"><img className="pvthmb" src={GertyFour} alt="Gerty Punks 1.0 NFT" /></div>
                            </div>
                          </div>
                          
                          
                          {/* EDIT: CONNECT MINT BUTTON */}
                          {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                          
                          <>
                            <s.TextTitle>
                              The sale has ended.
                            </s.TextTitle>
                          </>
                            ) : (
                          <>
                            {blockchain.account === "" ||
                              blockchain.smartContract === null ? (
                            <div>
                                <button type="button" className="btn btn-primary mt--20 pigbg"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(connect());
                                    getData();
                                    }}
                                >
                                  CONNECT
                                </button>
                                {blockchain.errorMsg !== "" ? (
                                  <>
                                    <s.SpacerSmall />
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                      }}
                                    >
                                      {blockchain.errorMsg}
                                    </p>
                                  </>
                                ) : null}
                              </div>
                          
                            ) : (
                            <>
                              <div>
                              <p>
                                {feedback}
                              </p>
                              </div>

                                <div className="mt--20 text-center">{/* EDIT: COUNT SELECTOR */}
                                  <button className="btn btn-primary-alta btn-small numbtn minusbtn"
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount();
                                      } }>
                                        -
                                  </button>

                                  <p className="numbox">
                                    {mintAmount}
                                  </p>

                                  <button className="btn btn-primary-alta btn-small numbtn plusbtn"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmount();
                                      } }
                                    >
                                      +
                                  </button>
                                </div>

                                <div>
                                  <button type="button" className="btn btn-primary mt--20 pigbg"
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTs();
                                      getData();
                                    } }
                                    >

                                    {claimingNft ? "BUSY" : "BUY"}
                                  </button>
                                </div>
                              </>
                            )}
                              </>
                            
                           )}
                           </div>
                                <div className="rn-bet-create">
                                  <div className="bid-list">
                                    <div className="countdown mt--15">{/* EDIT: NFT COUNTS */}
                                      <div className="countdown-container"><span className="countdown-value days-bottom">{data.totalSupply}</span><span className="countdown-heading days-top" /></div>
                                      <div className="countdown-container"><span className="countdown-value hours-bottom">{CONFIG.MAX_SUPPLY}</span><span className="countdown-heading hours-top">total</span></div>
                                    </div>
                                  </div>
                                  <div className="bid-list winning-bid">
                                    <div className="top-seller-inner-one mt--10">
                                      <div className="top-seller-wrapper">
                                        <div className="top-seller-content text-right">
                                          <span className="count-number">{/* EDIT: NFT PRICE */}
                                            1 NFT = TBD {CONFIG.NETWORK.SYMBOL} (excluding gas fees)  {/* {CONFIG.DISPLAY_COST}   */}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt--10 text-center">{/* EDIT: CONTRACT LINK */}
                                  <a className="color-primary" target="_blank" href="https://polygonscan.com/token/0xce1a3c6631c098dc4c31b29558ba0754026c3171">0xce1a3c6631c098dc4c31b29558ba0754026c3171</a>
                                </div>
                              <div className="property-wrapper-flex d-flex">{/* EDIT: NFT CATEGORIES */}
                                  <div className="rn-pd-sm-property-wrapper into-banner">
                                    <h6 className="pd-property-title">
                                      Categories
                                    </h6>
                                    <div className="catagory-wrapper">
                                      <div className="pd-property-inner">
                                        <span className="color-body type">STYLE</span>
                                        <span className="color-white value">Pixel</span>
                                      </div>
                                      <div className="pd-property-inner">
                                        <span className="color-body type">GENRE</span>
                                        <span className="color-white value">Retro</span>
                                      </div>
                                      <div className="pd-property-inner">
                                        <span className="color-body type">MEDIA</span>
                                        <span className="color-white value">Image</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="rn-pd-sm-property-wrapper into-banner ml--30">
                                    <h6 className="pd-property-title">
                                      Collection
                                    </h6>
                                    <div className="property-wrapper">
                                      <div className="pd-property-inner">
                                        <span className="color-body type">SET</span>
                                        <span className="color-white value">Gerty Punks 1.0</span>
                                      </div>
                                      <div className="pd-property-inner">
                                        <span className="color-body type">ARTIST</span>
                                        <span className="color-white value">Team Gerty</span>
                                      </div>
                                    </div>
                                  </div>
                                </div><br /><div className="place-bet-area into-banner" style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                  <p className="b1">{/* EDIT: INSTRUCTIONS */}
                                    *&nbsp;Please make sure you are connected to the right network (Polygon Mainnet) and the correct address. Please note: Once you make the purchase, you cannot undo this action.
                                    <br /><br />
                                    *&nbsp;We have set the gas limit to 285000 for the contract to successfully mint your NFT. We recommend that you don't lower the gas limit.
                                  </p>
                                </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-market-area rn-section-gapTop rn-section-gapBottom" id="about">
              <div className="container">
                <div className="row">
                  <div className="col-12">{/* EDIT: ABOUT DESCRIPTION */}
                    <div className="about-wrapper text-center">
                      <h2>The Gerty Punks 1.0 Collection</h2>
                      <p className="discription">
                        <span className="color-primary font-bold text-upper">Mint Date | </span>
                        &nbsp;TBD&nbsp;
                        <br />
                        <span className="color-primary font-bold text-upper">Mint Price | </span>
                        &nbsp; TBD &nbsp;MATIC    {/* {CONFIG.DISPLAY_COST}   */}
                        <br />
                        <span className="color-primary font-bold text-upper">Total NFTS |</span>
                        &nbsp;10,000&nbsp;
                    
                        <br />
                        <br />
                       
                        
                      </p>
                      <p className="discription">
                        <span className="color-primary font-bold text-upper">What's the purpose of this collection?</span>
                        <br />
                        The Gerty Punks Collection uses the popular 24 x 24 pixel art design made popular by the Crypto Punks team. 
                        The fully custom built collection includes 10,000 verifiably unique Gerty NFTs and over 135 randomly assigned attributes, 
                        meaning each Gerty Punk will look different and unique from the others. 
                        The artwork and development for this collection was created in collaboration with Artistoe, Artistoe DAO and the Gerty World Metaverse team.
                        This set of NFTs alongside the OG Gerty collection we launched first will be used to build out our metaverse Gerty World as well as provide unique 
                        opportunities for holders and new utility for the blockchain community.  
                        
                      </p>
                      <br />
                      <p className="discription">
                        <span className="color-primary font-bold text-upper">Which Gerty Punk will you get?!</span>
                        <br />
                        •&nbsp;5 Ultra Legendary Space Pigs&nbsp;•
                        <br />
                        •&nbsp;10 Legendary Gerty World Pigs&nbsp;•
                        <br />
                        •&nbsp;25 Golden Gerty&nbsp;•
                        <br />
                        •&nbsp;50 Trippy Gerty&nbsp;•
                        <br />
                        •&nbsp;75 Rainbow Gerty&nbsp;•
                        <br />
                        •&nbsp;10,000 Verifiably Unique Gerty Punks&nbsp;•
                      </p>
                      <br />
                      <p className="discription">
                        <span className="color-primary font-bold text-upper">Perks of owning a Gerty Punk</span>
                        <br />
                        •&nbsp;Access to the Gertyworld Metaverse&nbsp;•
                        <br />
                        •&nbsp;Access to the private chat channels in Discord&nbsp;•
                        <br />
                        •&nbsp;Access to the backend clubhouse pages in our DAPP&nbsp;•
                        <br />
                        •&nbsp;Early Access for all Gertyworld Drops and new releases&nbsp;•
                        <br />
                        •&nbsp;Priority access to the Gertyworld NFT Marketplace&nbsp;•
                        <br />
                        •&nbsp;Early access to land in the Gertyworld Metaverse&nbsp;•
                        <br />
                        •&nbsp;Gerty Token will be integrated with Gerty World to power the universe&nbsp;•
                        <br />
                        •&nbsp;Plans for a free Mutation mint incoming!&nbsp;•
                       
                      </p>
                      <br />
                      <p className="discription">
                      <span className="color-primary font-bold text-upper">Legendary Drops Prize Pool</span> <br />
                     
                        <span className="color-secondary font-bold text-upper" > $10,000 in MATIC Ultra Legnedary (5 total)</span> <br />
                        <span className="color-secondary font-bold text-upper"> $1,000 in MATIC Legendary (10 toatl)</span> <br />
                        <br />
                        **&nbsp;Prize pool based on atleast 50% sold. If less, prizes will still be honored but prorated. <br /> 
                        This ensures the team will have the budget to build out the metaverse. &nbsp;** <br />
                      </p><br />
                      <p className="discription">
                        <span className="color-primary font-bold text-upper"> 1,000 Whitelist Slots for Loyal Gerty Belivers!</span><br />
                      Members of the whitelist group will be able to mint Gerty Punks at a reduced price for the first 1,000 NFTs of this collection. 
                      During the whitelist you will be able to get a maximum of 3 NFTs from the 1,000 reserved at the reduced price. 
                      After the whitelist period is over, we will open sales to unlimited mints for everyone at the public price. 
                      < br /> < br /> 
                      All holders of OG Gerty NFTs will automatically be added to the whitelist. < br /> Thanks for being an OG!< br /> 
                      <br />
                        Weekly competitions will be held in Discord, with winners getting added to the whitelist. 
                        Learn more about our new discord server by clicking <a href="https://discord.gg/wX76d8Dtvq" target="_blank">Here</a><br />
                        
                      </p>
                      <br />
                      <p className="discription">{/* EDIT: NFT CALENDAR */}
                        <span className="color-primary font-bold text-upper">Coming soon on...</span>
                        <br />
                        <a href="https://nftcalendar.io/" target="_blank"><img src={NFTCalendarImg} style={{maxWidth: '180px'}} /></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rn-collection-area rn-section-gapTop rn-section-gapBottom" id="nfts">
              <div className="container">
                <div className="row mb--20 align-items-center">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                    <h2 className="title mb--0" data-sal-delay={150} data-sal="slide-up" data-sal-duration={800}>All NFT Collections</h2>
                  </div>
                </div>
                <div className="row g-5">
                  <div data-sal="slide-up" data-sal-delay={200} data-sal-duration={800} className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12">
                    <a href="https://og.gertrudetoken.com/" className="rn-collection-inner-one">{/* EDIT: GRID GERTY OG */}
                      <div className="collection-wrapper">
                        <div className="collection-big-thumbnail">
                          <img src={OGGerty1} alt="OG Gerty NFT" />
                        </div>
                        <div className="collenction-small-thumbnail">
                          <img src={OGGerty2} alt="OG Gerty NFT" />
                          <img src={OGGerty3} alt="OG Gerty NFT" />
                          <img src={OGGerty4} alt="OG Gerty NFT" />
                        </div>
                        <div className="collection-deg">
                          <h6 className="title" style={{fontFamily: '"Caesar Dressing"'}}>OG Gerty</h6>
                          <span className="items">420 NFTs</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div data-sal="slide-up" data-sal-delay={150} data-sal-duration={800} className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12">
                    <a href="https://" className="rn-collection-inner-one">{/* EDIT: GRID GERTY PUNKS 1.0 */}
                      <div className="collection-wrapper">
                        <div className="collection-big-thumbnail">
                          <img src="assets/images/gerty-punks-1-0/gerty-punks-1-0-samp04-298x199.png" alt="Gerty Punks 1.0" />
                        </div>
                        <div className="collenction-small-thumbnail">
                          <img src="assets/images/gerty-punks-1-0/gerty-punks-1-0-samp03-298x199.png" alt="Gerty Punks 1.0" />
                          <img src="assets/images/gerty-punks-1-0/gerty-punks-1-0-samp02-298x199.png" alt="Gerty Punks 1.0" />
                          <img src="assets/images/gerty-punks-1-0/gerty-punks-1-0-samp01-298x199.png" alt="Gerty Punks 1.0" />
                        </div>
                        <div className="collection-deg">
                          <h6 className="title" style={{fontFamily: '"VT323"', fontSize: 'var(--h5)'}}>Gerty Punks 1.0</h6>
                          <span className="items">10,000 NFTs</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="rn-footer-one rn-section-gap bg-color--1 mt--100 mt_md--80 mt_sm--80">
              <div className="container">
                <div className="row gx-5">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="widget-content-wrapper">
                      <div className="footer-left">
                        <div className="logo-thumbnail logo-custom-css">{/* EDIT: FOOT LOGO LINKS */}
                          <a className="logo-light" href="https://"><img src={GertyTopLight} alt="Gerty NFT" /></a>
                          <a className="logo-dark" href="https://"><img src={GertyTopDark} alt="Gerty NFT" /></a>
                        </div>
                        <p className="rn-footer-describe">
                          NFT Marketplace + Metaverse.<br />Profitability + Social Impact.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                    <div className="footer-widget widget-information">{/* EDIT: FOOT COLLECTION LINKS */}
                      <h6 className="widget-title">Collections</h6>
                      <ul className="footer-list-one">
                        <li className="single-list"><a href="https://og.gertrudetoken.com/">OG Gerty NFTs</a></li>
                        <li className="single-list"><a href="https://">Gerty Punks 1.0 NFTs</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                    <div className="footer-widget widget-information">{/* EDIT: FOOT SOCIAL LINKS */}
                      <h6 className="widget-title">Community</h6>
                      <ul className="footer-list-one">
                        <li className="single-list"><a href="https://twitter.com/gertytoken" target="_blank">Twitter</a></li>
                        <li className="single-list"><a href="https://t.me/GertrudeToken" target="_blank">Telegram</a></li>
                        <li className="single-list"><a href="https://discord.gg/wX76d8Dtvq" target="_bkank">Discord</a></li>
                        <li className="single-list"><a href="https://www.reddit.com/user/GertrudeToken/" target="_blank">Reddit</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_mobile--40">
                    <div className="footer-widget widget-quicklink">{/* EDIT: FOOT INFO LINKS */}
                      <h6 className="widget-title">Info</h6>
                      <ul className="footer-list-one">
                        <li className="single-list"><a href="https://opensea.io/collection/gertypunks" target="_blank">OpenSea</a></li>
                        <li className="single-list"><a href="https://nftcalendar.io/event/og-gerty-collection/" target="_blank">NFT Calendar</a></li>
                        <li className="single-list"><a href="https://gertrudetoken.com/" target="_blank">Gertrude Token</a></li>
                        <li className="single-list"><a href="https://medium.com/@Gertrudetoken" target="_blank">Medium</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copy-right-one ptb--20 bg-color--1">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-5 col-md-12 col-sm-12">
                    <div className="copyright-left">
                      <span>Copyright © 2021-2022 Gertrude Token. All Rights Reserved.</span>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12 col-sm-12">
                    <div className="copyright-left">{/* EDIT: DISCLAIMER */}
                      <small>LEGAL DISCLAIMER: NONE OF THE INFORMATION ON THIS WEBSITE SHOULD BE CONSTRUED AS PROVIDING LEGAL OR FINANCIAL ADVICE. PLEASE NOTE THERE ARE ALWAYS RISKS ASSOCIATED WITH SMART CONTRACTS. PLEASE USE AT YOUR OWN RISK. GERTRUDE TOKEN IS NOT A REGISTERED BROKER, ANALYST OR INVESTMENT ADVISOR. IF YOU ARE WILLING TO, OR HAVE PURCHASED GERTRUDE, YOU AGREE THAT YOU ARE NOT PURCHASING A SECURITY OR INVESTMENT. THE GERTRUDE TEAM CANNOT BE HELD LIABLE FOR ANY LOSSES OR TAXES YOU MAY INCUR. YOU ALSO AGREE THAT THE TEAM IS PRESENTING THE TOKEN AS IT WAS LAUNCHED, DUE TO IT BEING RENOUNCED IT CANNOT BE CHANGED. DO CONDUCT YOUR OWN DUE DILIGENCE AND CONSULT YOUR FINANCIAL ADVISOR BEFORE MAKING ANY INVESTMENT DECISIONS.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rn-progress-parent">
              <svg className="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
              </svg>
            </div>
          
          
{/*              require({ScriptsCoomponent }) */}
             {/*    <ScriptTag type="text/javascript" src="./assets/js/vendor/jquery.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/vendor/jquery.nice-select.min.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/vendor/feather.min.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/vendor/slick.min.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/vendor/bootstrap.min.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/vendor/sal.min.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/vendor/wow.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/vendor/particles.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/vendor/isotop.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/vendor/imageloaded.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/vendor/backtoTop.js"/>
                <ScriptTag type="text/javascript"  src="./assets/js/main-gerty.js" />  */}
               
            
          </div>
          
        );
      }
   
      document.body.style.backgroundColor = "#111111";   


export default App;



